import React from "react"
import { RichText } from "prismic-reactjs"

import { PrismicProject } from "../types/prismic"
import "./ProjectCard.scss"
import ChevronUp from "../assets/icons/chevron-up-small.svg"
import LazyImage from "./LazyImage"

type ProjectCardProps = {
  project: PrismicProject
  className: string
  onClick(s: string): void
}
const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  className,
  onClick,
}) => {
  if (project === null) return null
  const image = project.images?.[0]
  const cats = project.project_types
    .map(type => {
      return RichText.asText(type.type.title)
    })
    .join(", ")
  return (
    <a
      href="#"
      className={`project-card d--block ${className || ""}`}
      onClick={e => {
        e.preventDefault()
        onClick(project._meta.uid)
      }}
    >
      <div className="project-card__spacer">
        <LazyImage image={image.image} background />
      </div>
      <div className="project-card__content">
        <h2 className="project-card__title heading--4">
          {project.title ? RichText.asText(project.title) : ""}
        </h2>
        <div className="project-card__types heading--4 text--light">{cats}</div>
      </div>
      <div className="project-card__info hide--md-up text-align--center">
        <ChevronUp />
        <div className="heading--4 text--light">Info</div>
      </div>
    </a>
  )
}

export default ProjectCard
