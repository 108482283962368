import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Container, Row, Col } from "react-grid-system"

import SecondLevelPage from "./SecondLevelPage"
import { PrismicQuery, PrismicEdges, PrismicProject } from "../types/prismic"
import { TransitionProps } from "./ITransition"
import { NavActionStateTypes } from "../hooks/NavState"
import { useNavTree } from "../hooks/NavTreeState"
import { useSetRouteOverlay } from "../hooks/RouteOverlay"
import ProjectCard from "./ProjectCard"
import "./ProjectCollection.scss"
import { getRouteFromTree } from "../util/navTree"
import Project from "./Project"
import NavBars from "./NavBars"
import SEO from "./Seo"

// define types
type PageEdge = {
  _meta: {
    uid: string
  }
  title: string
  content: string
  projects: {
    project: PrismicProject
  }[]
}

type AllPages = {
  allProject_collections: PrismicEdges<PageEdge>
}

export const query = graphql`
  query ProjectCollectionQuery($uid: String) {
    prismic {
      allProject_collections(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            title
            content
            projects {
              project {
                ... on PRISMIC_Project {
                  _meta {
                    uid
                  }
                  title
                  content
                  images {
                    image
                  }
                  project_types {
                    type {
                      ... on PRISMIC_Project_type {
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface ProjectCollectionProps
  extends PrismicQuery<AllPages>,
    TransitionProps {
  uri: string
}
const ProjectCollection: React.FC<ProjectCollectionProps> = ({
  transitionStatus,
  data: prismicData,
  uri,
}) => {
  const data = prismicData.prismic.allProject_collections.edges?.[0]?.node
  if (!data) return null

  const [currentProject, setCurrentProject] = React.useState<null | string>(
    null
  )

  const setRouteOverlay = useSetRouteOverlay()

  // find the route to return to
  const navTree = useNavTree()
  const route = getRouteFromTree(navTree, "collection", data._meta.uid)
  if (route === "/") return null

  // find the current project Index
  const projectIdx =
    !!currentProject &&
    data.projects.findIndex(p => {
      return p.project?._meta.uid === currentProject
    })

  // if the current project exits then set it as the card
  if (projectIdx !== false && projectIdx > -1) {
    const closeAction = () => {
      setCurrentProject(null)
      setRouteOverlay({
        action: "hide",
      })
      window.history.pushState({}, "", uri)
    }
    const ProjectComponent = (
      <div className="project__wrap theme-light">
        <div className="project__nav hide--md-down">
          <NavBars to={uri} action={closeAction} iconState="back" />
        </div>
        <Project
          project={data.projects[projectIdx].project}
          returnTo={uri}
          closeAction={closeAction}
        />
      </div>
    )
    setRouteOverlay({
      action: "setComponent",
      component: ProjectComponent,
    })
    window.history.pushState({}, "", `/project/${currentProject}`)
  }

  const nextAction: NavActionStateTypes = {
    to: route,
    icon: "back",
  }

  return (
    <SecondLevelPage
      transitionStatus={transitionStatus}
      nextActionState={nextAction}
    >
      <SEO title={RichText.asText(data.title)} />
      <Container
        fluid
        style={{ paddingLeft: 0, paddingRight: 0 }}
        className="project-collection"
      >
        <Row gutterWidth={44}>
          <Col xl={4}>
            <h1>{RichText.asText(data.title)}</h1>
            {RichText.render(data.content)}
          </Col>
          <Col xl={8}>
            <div className="project-collection__project-divider hide--md-up sink-gutter" />
            {data.projects[0] && (
              <ProjectCard
                project={data.projects[0].project}
                className="project-collection__project project-collection__project--featured sink-gutter"
                onClick={setCurrentProject}
              />
            )}
            <Row gutterWidth={44}>
              {data.projects.slice(1).map((p, idx) => (
                <Col md={6} key={`project-${idx}`}>
                  <div className="project-collection__project-divider hide--md-up sink-gutter" />
                  <ProjectCard
                    project={p.project}
                    className="project-collection__project sink-gutter"
                    onClick={setCurrentProject}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </SecondLevelPage>
  )
}

export default ProjectCollection
